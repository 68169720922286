import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/entity-framework-code-first",
  "date": "2014-07-21",
  "title": "ENTITY FRAMEWORK CODE FIRST",
  "author": "admin",
  "tags": ["development", "csharp"],
  "featuredImage": "feature.jpg",
  "excerpt": "Entity Framework introduced Code First approach from Entity Framework 4.1. This tutorial covers all the features of Entity Framework Code-First. We will start with the basics of code-first and then we will see how we can configure one-to-one, one-to-many and many-to-many relationship with DataAnnotation as well as Fluent API."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We will also see migration which was introduced in EF 4.3. We will use Entity Framework 4.1 and 4.3/5.0 in this tutorial.`}</p>
    <h2>{`What is Code-First?`}</h2>
    <p>{`Entity Framework introduced Code First approach from Entity Framework 4.1. Code First is mainly useful in Domain-Driven Design In the Code First approach, you can focus on the domain design and start creating classes as per your domain requirement rather than design your database first and then create the classes which match your database design. Code First APIs will create the database on the fly based on your entity classes and configuration.  So as a developer, you first start writing C#/VB.net classes rather than focusing on database design and then when you run the application, Code First APIs will create the new database or map your classes with existing database before running your application. So the basic workflow would be:  Write code-first application classes → Hit F5 to run the application → Code First API creates new database or map with existing database from application classes → Inserts default/test data into the database → Finally launch the application`}</p>
    <h2>{`Simple Code First Example`}</h2>
    <p>{`Let’s create a simple code-first example.  Create a Class Library project in Visual Studio. Write two simple Movie and Actor entity classes as below:`}</p>
    <p><strong parentName="p">{`Actor class:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`public class Actor
{
    public Actor()
    { 
    }
    public int ID { get; set; }
    public string FullName { get; set; }
}
`}</code></pre>
    <p><strong parentName="p">{`Movie class:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`public class Movie
{
    public Movie()
    { 
    }
    public int ID { get; set; }
    public string Title { get; set; }
}
`}</code></pre>
    <p>{`Now, create context class which is derived from DBContext class with two DbSet properties, one for Actor and one for Movie.	`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`namespace MovieDataLayer
{
    
    public class Context: DbContext 
    {
        public Context(): base()
        {
    
        }
        public DbSet<Actor> Actors { get; set; }
        public DbSet<Movie> Movies { get; set; }
    }
}
`}</code></pre>
    <p>{`Now, create console project to test these classes as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using (var ctx = new Context())
{
    Actor actor = new Actor() { FullName = "Will Smith" };
    ctx.Actors.Add(actor);
    ctx.SaveChanges();                
}
`}</code></pre>
    <p>{`Now, if you run the application then you will surprise to see that application run successfully.`}</p>
    <p>{`It has successfully stored Actor information into the database. But where is the database and what are the tables and its columns?`}</p>
    <p>{`This is the beauty of Code First APIs of Entity Framework. It creates the database based on the parameter passed in the base constructor of your context class. We have not passed any parameters so it will create `}<strong parentName="p">{`ActorDataLayer.Context`}</strong>{` database in local SQLEXPRESS`}</p>
    <p>{`Code-first API will also create two tables in the database, Actors and Movies table based on Actor and Movie class. Code First API creates PrimaryKey in the table if class has either `}<strong parentName="p">{`Id`}</strong>{` or ClassName + `}<strong parentName="p">{`id`}</strong>{` property. For example, the Actor class has "ID" property so it will create ID as a PK column. It will also create other columns with the same name and datatype as property names and datatypes.`}</p>
    <p>{`So this way without creating database first, you can start writing application that will eventually create the database from your domain classes.`}</p>
    <h2>{`Database Initialization`}</h2>
    <p>{`Let’s how database initialize in code-first application.`}</p>
    <p>{`No Parameter: If you do not pass the parameter then it creates the database in your local SQLEXPRESS with name matches your {Namespace}.{Context class name}. eg. MovieDataLayer.Context for following context:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`public class Context: DbContext 
{
    public Context(): base()
    {
    }
}
`}</code></pre>
    <p>{`Name: If you pass `}<strong parentName="p">{`Name`}</strong>{` parameter then it creates database in the local SQLEXPRESS DB server using that name. For example: below code will create `}<strong parentName="p">{`ActorDB-CodeFirst`}</strong>{` database`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`public class Context: DbContext 
{
    public Context(): base("MovieDB-CodeFirst") 
    {
    }
}
`}</code></pre>
    <p>{`ConnectionStringName: If you pass connection string name of `}<inlineCode parentName="p">{`app.config`}</inlineCode>{` or `}<inlineCode parentName="p">{`web.config`}</inlineCode>{` then it will create the database as per connection string. For example, below code will create "ActorDB-ByConnectionString" database:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`public class Context: DbContext 
{
    public ActorDBContext() : base("ActorDBConnectionString") 
    {
    }
}
`}</code></pre>
    <p>{`App.config:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`<?xml version="1.0" encoding="utf-8" ?>
<configuration>
  <connectionStrings>
    <add name="MovieDBConnectionString" 
    connectionString="Data Source=.;Initial Catalog=ActorDB-ByConnectionString;Integrated Security=true" 
    providerName="System.Data.SqlClient"/>
  </connectionStrings>
</configuration>
`}</code></pre>
    <p>{`Above Context class sends connection string name as a parameter. So it will create new `}<strong parentName="p">{`MovieDB-ByConnectionString`}</strong>{` database or use existing `}<strong parentName="p">{`MovieDB-ByConnectionString`}</strong>{` database at local SQL Server (because we have mentioned '.' not SQLEXPRESS). Make sure that you include providerName = "System.Data.SqlClient" in the connection string.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      